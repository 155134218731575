var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.valid
    ? _c(
        "b-card",
        {
          staticClass: "product-card-component",
          class: {
            disabled: _vm.disabled,
            "product-selected": _vm.slideProductSelected(
              _vm.hydratedSlideProduct
            ),
          },
          attrs: { "footer-bg-variant": "transparent" },
          on: { click: _vm.choose },
          scopedSlots: _vm._u(
            [
              _vm.showFooter
                ? {
                    key: "footer",
                    fn: function () {
                      return [
                        _vm.showFiledIn
                          ? _c(
                              "b-card-text",
                              {
                                staticClass: "filed-in-text",
                                class: {
                                  "complete-package":
                                    _vm.slideProduct.position === 1,
                                },
                              },
                              [
                                _vm.filedIn
                                  ? _c("p", [
                                      _vm._v(
                                        "\n        State Processing Time "
                                      ),
                                      _c("span", [_vm._v(_vm._s(_vm.filedIn))]),
                                    ])
                                  : _c("i", [
                                      _vm._v(
                                        " State Processing Time time varies "
                                      ),
                                    ]),
                              ]
                            )
                          : _vm._e(),
                        !_vm.isFreeOrNullPrice
                          ? _c("b-card-text", [
                              _c("div", [
                                _c("p", [_vm._v(_vm._s(_vm.priceDescription))]),
                              ]),
                              _c("div", [
                                _c("b", [_vm._v(_vm._s(`$${_vm.price}`))]),
                              ]),
                              _c("div", [
                                _c("p", { staticClass: "info" }, [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.purchaseInfo) +
                                      "\n        "
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.slideProduct.button_text
                          ? _c(
                              "b-button",
                              {
                                staticClass: "borderless",
                                attrs: {
                                  variant: "primary",
                                  "aria-label": "select",
                                },
                              },
                              [
                                _c("b", [
                                  _vm._v(
                                    "\n        " +
                                      _vm._s(_vm.slideProduct.button_text) +
                                      "\n      "
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _vm.slideProductSelected(_vm.hydratedSlideProduct)
            ? _c(
                "div",
                [
                  _c("fa-icon", {
                    staticClass: "cart-icon",
                    attrs: { icon: "shopping-cart" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.slideProduct.image_urls
            ? _c("div", { staticClass: "card-image-container" }, [
                _c("img", {
                  staticClass: "card-image",
                  attrs: { src: _vm.imageUrl, alt: "slideProduct.title" },
                }),
              ])
            : _vm._e(),
          _c("h4", { staticClass: "product-title" }, [
            _vm._v("\n    " + _vm._s(_vm.slideProduct.title) + "\n  "),
          ]),
          _c("b-card-text", { staticClass: "text-left" }, [
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.productDescription) },
            }),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }